<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Summary
      </h1>
    </page-header>
    <v-row>
      <v-col class="col-12 py-0">
        <v-row>
          <v-col
            xs="1"
          >
            <router-link
              :to="{
                name: 'notifications',
                query: { 'send-status': 'planned' }
              }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ notifications ? notifications.planned : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center">
                    Planned notifications
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{
                name: 'notifications',
                query: { 'send-status': 'to_send' }
              }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ notifications ? notifications.to_send : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center primary--text">
                    Notifications "To send"
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'notifications', query: { 'send-status': 'sent' } }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ notifications ? notifications.sent : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center success--text">
                    Sent notifications
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{
                name: 'notifications',
                query: { 'send-status': 'failed' }
              }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ notifications ? notifications.failed : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center error--text">
                    Failed notifications
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'notifications' }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ times ? times.count : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center">
                    Total notifications
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'reminders' }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ schedules ? schedules.count : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center">
                    Total reminders
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <v-card class="py-6">
              <v-card-title class="justify-center">
                <h1>{{ sessions ? sessions.completed : 0 }}</h1>
              </v-card-title>
              <v-card-text class="mt-3">
                <div class="title text-center">
                  Completed sessions
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'logs' }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ logs ? logs.count : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center">
                    Total logs
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'subscriptions' }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ subscriptionsLength }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center">
                    Total subscriptions
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
 
          <v-col
            xs="1"
          >
            <router-link
              :to="{ name: 'logs', query: { type: 'error' } }"
              class="dashboard-link"
            >
              <v-card class="py-6">
                <v-card-title class="justify-center">
                  <h1>{{ errors ? errors.count : 0 }}</h1>
                </v-card-title>
                <v-card-text class="mt-3">
                  <div class="title text-center error--text">
                    Errors
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        xs="1"
      >
        <router-link
          :to="{ name: 'contacts' }"
          class="dashboard-link"
        >
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <h1>{{ contacts ? contacts.count : 0 }}</h1>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="title text-center">
                Contact requests
              </div>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col
        xs="1"
      >
        <v-card class="py-6">
          <v-card-title class="justify-center">
            <v-file-input
              v-model="uploadFile"
              label="Upload CSV"
              @change="processFile"
            />
          </v-card-title>
          <v-card-text class="mt-3">
            <div class="title text-center">
              {{ importMessage }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        xs="1"
      >
        <div class="dashboard-link">
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <v-btn
                color="primary"
                :disabled="exportCalling"
                @click="exportReviews"
              >
                <span v-if="!exportCalling">Export</span>
                <span v-if="exportCalling">Loading..</span>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="title text-center">
                Export data<br><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <br>
    <h1 class="mb-4">
      Feature Flags
    </h1>
    <v-row>
      <v-col
        xs="1"
      >
        <div class="dashboard-link">
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <v-btn
                color="primary"
                @click="toggleFeatureFlags('selfMonitoringAndExercisesGraphs', 'emotionsGraphTitle')"
              >
                <span v-if="!emotionsGraphTitle">Enable</span>
                <span v-if="emotionsGraphTitle">Disable</span>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <div 
                v-if="!emotionsGraphTitle" 
                class="title text-center"
              >
                New emotions graph: <span class="error--text">Disabled</span>
                <br><br>
              </div>
              <div 
                v-if="emotionsGraphTitle" 
                class="title text-center"
              >
                New emotions graph: <span class="success--text">Enabled</span>
                <br><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col   
        xs="1"
      >
        <!-- Feature flag test component (uncomment to test) -->
        <!-- <feature flag-name="selfMonitoringAndExercisesGraphs">
              <feature-test title="Test Feature 1" />
            </feature> -->
      </v-col>
    </v-row>
    <br>
    <h1 class="mb-4">
      Premium tier test
    </h1>
    <p>This is for the account you are currently using.</p>
    <p>Look at the firestore document for this account under the flags field for results.</p>
    <v-row>
      <!-- Tier 1 test-->
      <v-col
        xs="1"
      >
        <div class="dashboard-link">
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <v-btn
                color="primary"
                @click="toggleTestTier('premiumFeatures1', 'tier1Title')"
              >
                <span v-if="!tier1Title">Enable</span>
                <span v-if="tier1Title">Disable</span>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <div 
                v-if="!tier1Title" 
                class="title text-center"
              >
                Tier 1: <span class="error--text">Disabled</span>
                <br><br>
              </div>
              <div 
                v-if="tier1Title" 
                class="title text-center"
              >
                Tier 1: <span class="success--text">Enabled</span>
                <br><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <!-- Tier 2 test-->
      <v-col
        xs="1"
      >
        <div class="dashboard-link">
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <v-btn
                color="primary"
                @click="toggleTestTier('premiumFeatures2', 'tier2Title')"
              >
                <span v-if="!tier2Title">Enable</span>
                <span v-if="tier2Title">Disable</span>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <div 
                v-if="!tier2Title" 
                class="title text-center"
              >
                Tier 2: <span class="error--text">Disabled</span>
                <br><br>
              </div>
              <div 
                v-if="tier2Title" 
                class="title text-center"
              >
                Tier 2: <span class="success--text">Enabled</span>
                <br><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <!-- Tier 3 test-->
      <v-col
        xs="1"
      >
        <div class="dashboard-link">
          <v-card class="py-6">
            <v-card-title class="justify-center">
              <v-btn
                color="primary"
                @click="toggleTestTier('premiumFeatures3', 'tier3Title')"
              >
                <span v-if="!tier3Title">Enable</span>
                <span v-if="tier3Title">Disable</span>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <div 
                v-if="!tier3Title" 
                class="title text-center"
              >
                Tier 3: <span class="error--text">Disabled</span>
                <br><br>
              </div>
              <div 
                v-if="tier3Title" 
                class="title text-center"
              >
                Tier 3: <span class="success--text">Enabled</span>
                <br><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <br>
    <h1 class="mb-4">
      App-wide Adherence Rate
    </h1>
    <v-row>
      <v-col class="col-12">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          min-width="290"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              Pick a start date
            </v-btn>
          </template>
          <v-date-picker
            v-model="selectedDate"
            :allowed-dates="disableFutureDates"
            @input="refreshAdherenceRateTitle"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <card-summary 
          image="card-adherence.svg"
          :card-header="`Adherence rate from the past ${daysSinceSelectedDate} days`"
          :number-header="adherenceRate === null ? 'Loading...' : adherenceRate + '%'"
          :show-rate-change="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <h1 class="display-1">
          Last errors
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <tab-logs />
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import firebase from '@/firebase'
import DashboardContent from '@/components/dashboard/DashboardContent'
import TabLogs from '@/views/dashboard/admin/TabLogs'
import rawAPI from '@/helpers/rawAPI'
import CardSummary from '@/components/dashboard/CardSummary'
import PageHeader from '@/components/dashboard/PageHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import { 
  calculateAdherenceRate,
  getDaysSinceDocument, 
  getCompleteAndSent
} from '@/kit/helpers/summaryFunctions.js'

export default {
  name: 'IndexPage',
  components: { DashboardContent, TabLogs, CardSummary, PageHeader },
  data() {
    return {
      contacts: null,
      notifications: null,
      errors: null,
      schedules: null,
      sessions: null,
      times: null,
      exportCalling: false,
      emotionsGraphTitle: false,
      tier1Title: false,
      tier2Title: false,
      tier3Title: false,
      logs: null,
      adherenceRate: null,
      menu: false,
      selectedDate: null,
      daysSinceSelectedDate: 365,
      subscriptionsLength: 0,
      fileCount: 0,
      uploadFieldName: '',
      usersLoaded: false,
      uploadFile: null,
      loadingUsers: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user'
    }),
    importMessage() {
      return this.usersLoaded ? 'Users imported!' : this.loadingUsers ? 'Importing users' : 'Import users'
    }
  },
  watch: {
    selectedDate() {
      const now = new Date()
      const selected = new Date(this.selectedDate)
      const diffTime = Math.abs(now - selected)
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
      this.daysSinceSelectedDate = diffDays
    }
  },
  async mounted() {
    this.$bind(
      'notifications',
      this.$firebase.firestore().doc('counters/notifications')
    )
    this.$bind('errors', this.$firebase.firestore().doc('counters/errors'))
    this.$bind(
      'schedules',
      this.$firebase.firestore().doc('counters/schedules')
    )
    this.$bind('times', this.$firebase.firestore().doc('counters/times'))
    this.$bind('logs', this.$firebase.firestore().doc('counters/logs'))
    this.$bind('contacts', this.$firebase.firestore().doc('counters/contacts'))
    this.$bind('sessions', this.$firebase.firestore().doc('counters/sessions'))
    
  },
  async created() {
    this.emotionsGraphTitle = this.user.flags['selfMonitoringAndExercisesGraphs'].value
    this.tier1Title = this.user.flags['premiumFeatures1'].value
    this.tier2Title = this.user.flags['premiumFeatures2'].value
    this.tier3Title = this.user.flags['premiumFeatures3'].value
    const subscriptions = await this.getSubscriptions()
    let length = 0
    subscriptions.forEach(sub => {
      if (sub?.first_name && sub?.last_name && sub.email && sub.subscription) {
        length = length + 1
      }
    })
    this.subscriptionsLength = length
    await this.refreshAdherenceRateTitle()
  },
  methods: {
    ...mapActions({
      addUser: 'authentication/addUser',
      createUser: 'authentication/createUser',
      changeFlag: 'authentication/changeFlag',
      changeUserFlag: 'authentication/changeUserFlag',
      getSubscriptions: 'authentication/getSubscriptions'
    }),
    disableFutureDates(val) {
      return val < new Date().toISOString().substr(0, 10)
    },
    async refreshAdherenceRateTitle() {
      this.menu = false
      /**
       * Look at src/views/dashboard/Summary.vue lines 107-316
       * for general summary version (by-user)
       */
      if (!this.user) {
        throw new Error('User is not signed in')
      }
      this.adherenceRate = null

      // Get the global adherence rate
      try {
        let reviews = await this.$firebase.firestore().collection('reviews').get()
        
        // Filter out reviews that are older than 365 days
        const reviewsWithinPastYear = reviews.docs?.filter(review => {
          const daysSinceReminder = getDaysSinceDocument(review.data().schedule?.createdAt)
          return (daysSinceReminder <= this.daysSinceSelectedDate)
        }) ?? []
        
        const { complete, sent } = getCompleteAndSent(reviewsWithinPastYear)
        this.adherenceRate = Math.round(calculateAdherenceRate(complete, sent))
      } catch (e) {
        console.error('Error calculating global adherence rate')
        throw e
      }
    },
    
    /**
     * Toggles boolean value for a given feature's boolean
     * object for feature visibility
     * @param {string} featureName 
     */
    async toggleFeatureFlags(featureName, currValue) {
      this.changeFlag({
        name: featureName,
        set: { value: !this[`${currValue}`] }
      })
      this[`${currValue}`] = !this[`${currValue}`]
    },

    async toggleTestTier(featureName, currValue) {
      this.changeUserFlag({
        name: featureName,
        uid: this.user.id,
        set: { value: !this[`${currValue}`]}
      })
      // this.user.flag[featureName] = { value: !this[`${currValue}`]} //! Error
      // this.changeUserFlag({
      //   uid: this.user.id,
      //   flag: {
      //     ...this.user.flag
      //   }
      // })
      this[`${currValue}`] = !this[`${currValue}`]
    },

    download(filename, text) {
      const element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
      )
      element.setAttribute('download', filename)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    async exportReviews() {
      this.exportCalling = true
      const res = await rawAPI('/export-stream/reviews', undefined, {
        method: 'GET',
      })
      this.exportCalling = false
      // Date the export
      this.download(`export-${new Date().toISOString()}.csv`, res)
    },
    async processFile(file) {
      this.loadingUsers = true
      const reader = new FileReader();
      const users = []
      reader.addEventListener(
        "load",
        async () => {
          // this will then display a text file
          const randomPassword = () => {
            return Math.floor(Math.random() * Date.now()).toString(36);
          }
          const text = reader.result;
          const lines = text.split('\n');
          lines.forEach(line => {
            const columns = line.split(',');
            // Handle the columns array
            users.push({
              role: 'provider',
              first_name: columns[0],
              last_name: columns[1],
              email: columns[2],
              organization: columns[3],
            })
          });
          users.shift()
          users.pop()
          // const testUser = {first_name: 'jeff', last_name: 'brayne', email: 'jeffrey.brayne+csv10@doxy.me', createdAt: firebase.now()}
          // testUser.password = randomPassword()
          // const testAddedUser = await this.createUser(testUser)
          // await firebase.auth().sendPasswordResetEmail(testUser.email)
          // .catch((error) => {
          //   console.log('error: ', error)
          // })

          // await firebase.db
          //   .collection('users')
          //   .doc(testAddedUser.uid)
          //   .set(testUser)
          

          const createUsers = () => new Promise((resolve) => {
            try {
              users.forEach(async (user, index) => {
                const password = randomPassword()
                const addedUser = await this.createUser({email: user.email, password: password})
                if (addedUser) {
                  await firebase.auth().sendPasswordResetEmail(user.email)
                  .catch((error) => {
                    console.log('error: ', error)
                  })
                  user.createdAt = firebase.now()
                  await firebase.db
                  .collection('users')
                  .doc(addedUser.uid)
                  .set(user)
                }
                if (index === (users.length - 1)) {
                  resolve()
                }
              })
            } catch (e) {
              console.log('Error', e)
            }
          })

          await createUsers()
          this.loadingUsers = false
          this.usersLoaded = true
          this.uploadFile = null
        },
        false,
      );

      if (file) {
        await reader.readAsText(file);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;
}
.v-application a.dashboard-link {
  height: 100%;
  text-decoration: underline !important;
}

</style>

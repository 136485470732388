<template>
  <v-simple-table style="border-collapse: collapse">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left d-block d-sm-table-cell">
            Created at
          </th>
          <th class="text-left d-block d-sm-table-cell">
            Session ID
          </th>
          <th class="text-left d-block d-sm-table-cell">
            Function
          </th>
          <th class="text-left d-block d-sm-table-cell">
            Message
          </th>
          <th class="text-left d-block d-sm-table-cell">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="log in logs"
          :key="log.id"
          :class="viewportWidth < 768 ? 'mobile' : ''"
        >
          <td class="d-block d-sm-table-cell">
            {{ formatDatetimeLocal(log.createdAt) }}
          </td>
          <td class="d-block d-sm-table-cell">
            {{ log.sessionId }}
          </td>
          <td class="d-block d-sm-table-cell">
            {{ log.functionName }}
          </td>
          <td class="d-block d-sm-table-cell">
            {{ log.message }}
          </td>
          <td class="d-block d-sm-table-cell">
            <v-icon
              small
              class="mr-2"
              @click="showItem(log.id)"
            >
              {{ mdiEye }}
            </v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mdiEye } from '@mdi/js'

export default {
  data() {
    return {
      logs: [],
      mdiEye,
      viewportWidth: window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    return this.$bind(
      'logs',
      this.$firebase
        .firestore()
        .collection('logs')
        .where('type', '==', 'error')
        .orderBy('createdAt', 'desc')
        .limit(50)
    )
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    showItem(id) {
      this.$router.push({ name: 'logShow', params: { id } })
    }
  }
}
</script>
<style scoped>

>>>.v-data-table__wrapper table {
  border-collapse: collapse;
}
.mobile {
  border: 1px solid var(--v-secondary-darken2);
}
</style>